import http from 'services/http';
import { CaseStudyPageType } from 'services/discovery/casestudy';
import { ContactPageType } from 'services/contact';
import { GuidesPageType } from 'services/discovery/guides';
import { HomePageType } from 'services/home_page';
import { NewsPageType } from 'services/discovery/news';
import { PageProductType } from 'services/product';
import { PichonkunPageType } from 'services/discovery/pichonkun';
import { ServicesPageType } from 'services/services';
import { FaqPageType } from 'services/services/faq';
import { CataloguePageType } from 'services/services/catalogue';
import { ErrorCodePageType } from 'services/services/error_codes';
import { SmartAcSelectorPageType } from 'services/services/smart_ac_selector';
import { MaintenancePageType } from 'services/services/maintenance';
import { WarrantyPolicyPageType } from 'services/services/warranty_policy';
import { HaNoiSolutionPlazaPageType } from 'services/discovery/hanoi_solution_plaza';
import { AwardsPageType } from 'services/about_us/daikin_vietnam/awards';
import { DaikinVietNamPageType } from 'services/about_us/daikin_vietnam';
import { CsrPageType } from 'services/about_us/daikin_vietnam/csr';
import { HistoryPageType } from 'services/about_us/daikin_vietnam/history';
import { DaikinFactoryPageType } from 'services/about_us/daikin_vietnam/daikin_factory';
import { TechnologyPageType } from 'services/discovery/technology';
import { SearchPageType } from 'services/search';
import { SolutionPageType } from 'services/solution';
import { WhereToBuyPageType } from 'services/wheretobuy';
import { AboutUsPageType } from 'services/about_us';
import { DaikinGroupPageType } from 'services/about_us/daikin_group';
import { DiscoveryPageType } from 'services/discovery';
import { PartnerPageType } from 'services/partner';
import { DaikinDealerPageType } from 'services/partner/daikin_dealer';
import { TrainingMemberPageType } from 'services/partner/training/training_member';
import { MemberShipCardPageType } from 'services/partner/training/member_ship_card';
import { SalesCoursesPageType } from 'services/partner/training/sales_courses_schedule';
import { ServiceCoursesPageType } from 'services/partner/training/service_courses_schedule';
import { TermsAndConditionsPageType } from 'services/terms_and_conditions';
import { GoodServicePoliciesPageType } from 'services/services/goods_service_policies';

export interface ExtraMeta {
  ogDescription: string;
  ogImage: string;
  ogTitle: string;
  ogType: string;
  canonical: string;
}
export interface MetaDataType {
  title: string;
  description: string;
  keywords: string;
  extraData: ExtraMeta;
}

export type BreadcrumbItemType = { type: string; text: string; slug: string };
export type BreadcrumbsType = Array<BreadcrumbItemType>;

interface CommonPageType {
  metaData: MetaDataType;
  templateCode: string;
  translations: Array<{
    locale: string;
    slug: string;
  }>;
}
interface NotfoundPageType extends CommonPageType {
  title: string;
  pageMetaData: {
    '404SectionContent0': {
      heading: string;
      content: string;
      image: string;
      link: {
        text: string;
        link: string;
      };
    };
  };
}

export interface PageResponse {
  data: {
    data: NotfoundPageType &
      SolutionPageType &
      PageProductType &
      ContactPageType &
      CaseStudyPageType &
      HomePageType &
      WhereToBuyPageType &
      NewsPageType &
      GuidesPageType &
      PichonkunPageType &
      HaNoiSolutionPlazaPageType &
      ServicesPageType &
      FaqPageType &
      CataloguePageType &
      ErrorCodePageType &
      SmartAcSelectorPageType &
      MaintenancePageType &
      GoodServicePoliciesPageType &
      WarrantyPolicyPageType &
      AwardsPageType &
      DaikinVietNamPageType &
      CsrPageType &
      HistoryPageType &
      DaikinFactoryPageType &
      TechnologyPageType &
      SearchPageType &
      AboutUsPageType &
      DaikinGroupPageType &
      DiscoveryPageType &
      PartnerPageType &
      DaikinDealerPageType &
      TrainingMemberPageType &
      MemberShipCardPageType &
      SalesCoursesPageType &
      ServiceCoursesPageType &
      TermsAndConditionsPageType;
    metaData: MetaDataType;
    breadcrumbs: BreadcrumbsType;
  };
  message: string;
  success: boolean;
}

export interface FloatingBannerData {
  id: number;
  image: string;
  isActive: number;
  createdAt: string;
  updatedAt: string;
  locale: string;
  content: string;
  button: {
    consult: {
      icon: string;
      text: string;
    };
    contact: {
      icon: string;
      text: string;
    };
  };
  translations: Array<{
    id: number;
    floatingBannerId: number;
    locale: string;
    content?: string;
    button: {
      consult: {
        icon: string;
        text: string;
      };
    };
  }>;
}

export const getPage = async (slug: string): Promise<PageResponse> => {
  const { data } = await http.get(`/page/${slug}`, { shouldCheckErrorPage: true });
  return data;
};

export const getFloatingBannerService = async (): Promise<FloatingBannerData> => {
  const { data } = await http.get('/floating-banners', { shouldCheckErrorPage: true });
  return data.data;
};
